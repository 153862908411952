/* This example requires Tailwind CSS v2.0+ */
import React from 'react';
import {PageProps} from 'gatsby';

import SEO from '../components/seo';
import NavBar from '../components/NavBar';
// import MyLink from '../components/MyLink';
// import Footer from '../components/Footer';

const TITLE = 'Hello 👋';

export default function Example(props: PageProps) {
  return (
    <div className="bg-white">
      <SEO
        title="Joe DiVittorio"
        includeMainTitle={false}
        // image={hero}
      />
      <NavBar {...props} />
      <main className="overflow-hidden">
        {/* <div className="absolute inset-x-0 bottom-0 h-1/2 bg-my-tan" /> */}
        <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          {/* <div className="mt-24 hidden lg:block bg-my-tan absolute top-0 bottom-0 left-3/4 w-screen" /> */}
          <div className="mx-auto text-base z-10 max-w-prose lg:grid lg:grid-cols-1 lg:gap-8 lg:max-w-none">
            <h3 className="mt-2 text-3xl leading-8 contents lg:hidden font-extrabold tracking-tight text-my-color sm:text-4xl">
              {TITLE}
            </h3>
          </div>
          <div className="mt-6 sm:mt-28 lg:grid lg:grid-cols-2 lg:gap-20">
            <div className="relative lg:row-start-1 lg:col-start-2">
              <div className="relative text-base mx-auto max-w-prose lg:max-w-none">
                <figure>
                  <div className="aspect-w-12 aspect-h-12 lg:aspect-none"></div>
                </figure>
              </div>
            </div>
            <div className="mt-8 lg:mt-0">
              <h3 className="mt-8 text-3xl hidden lg:contents font-extrabold tracking-tight text-my-color sm:text-4xl">
                {TITLE}
              </h3>
              <div className="mt-5 text-md lg:text-lg prose text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
                <p></p>
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </main>
    </div>
  );
}
