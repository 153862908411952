import React, {Fragment} from 'react';
import {PageProps} from 'gatsby';
import {Popover, Transition} from '@headlessui/react';
import {MenuIcon, XIcon} from '@heroicons/react/outline';
// import { ChevronDownIcon, CameraIcon } from "@heroicons/react/solid";

import Logo from './Logo';
import MyLink from './MyLink';

interface Item {
  name: string;
  link: string;
  linkType: string;
  href: string;
}

const navigation: Item[] = [
  // {
  //   name: 'About',
  //   link: 'nav_about_clicked',
  //   linkType: 'page_link',
  //   href: '/about',
  // },
];

const NavBar = (props: PageProps) => {
  return (
    <header className="sticky top-0 z-50 border-b">
      <Popover className="relative bg-white w-full">
        <div className="flex w-full bg-white justify-between items-center max-w-7xl mx-auto px-4 py-6 sm:px-6 md:space-x-10 lg:px-8">
          <div className="flex flex-row justify-start items-center lg:w-0 lg:flex-1">
            <MyLink href="/" linkType="page_link" eventName="nav_home_clicked">
              <Logo />
            </MyLink>
            <MyLink
              href="/"
              linkType="page_link"
              eventName="nav_home_clicked"
              className="mx-4 text-base font-medium text-2xl text-my-color hover:text-blue-600"
            >
              Joe
            </MyLink>
          </div>
          {navigation.length > 0 ? (
            <div className="-mr-2 -my-2 md:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-800">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
          ) : undefined}
          {navigation.length > 0 ? (
            <Popover.Group as="nav" className="hidden md:flex space-x-10">
              {navigation.map(item => {
                const isSelected = props.location.pathname.includes(item.href);
                return (
                  <MyLink
                    key={item.name}
                    href={item.href}
                    // @ts-ignore
                    linkType={item.linkType}
                    eventName={item.link}
                    className={`text-base font-medium text-gray-500 hover:text-gray-900 ${
                      isSelected ? 'underline' : ''
                    }`}
                  >
                    {item.name}
                  </MyLink>
                );
              })}
            </Popover.Group>
          ) : undefined}
          {/* <MyLink
            href="#"
            className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-my-color hover:bg-blue-700"
          >
            Contact
          </MyLink> */}
          {/* </div> */}
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <Logo className="h-8 w-auto" />
                    {/* <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                      alt="Workflow"
                    /> */}
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-800">
                      <span className="sr-only">Close menu</span>
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
                <div className="py-6 px-5">
                  {/* <div className="grid grid-cols-2 gap-4">
                    {navigation.map(item => {
                      const isSelected = props.location.pathname.includes(item.href);
                      return (
                        <MyLink
                          key={item.name}
                          href={item.href}
                          // @ts-ignore
                          linkType={item.linkType}
                          eventName={item.link}
                          className={`text-base font-medium text-gray-900 hover:text-gray-700 ${
                            isSelected ? 'underline' : ''
                          }`}
                        >
                          {item.name}
                        </MyLink>
                      );
                    })}
                  </div> */}
                  {/* <div className="mt-6">
                  <nav className="grid grid-cols-1 gap-7">
                  </nav>
                </div> */}
                </div>
                {/* <div className="mt-6">
                  <MyLink
                    href="#"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                  >
                    Sign up
                  </MyLink>
                  <p className="mt-6 text-center text-base font-medium text-gray-500">
                    Existing customer?
                    <MyLink href="#" className="text-gray-900">
                      Sign in
                    </MyLink>
                  </p>
                </div> */}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </header>
  );
};

export default NavBar;
